import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ActionModal from '../common/ActionModal'
import Apply from '../common/Apply'
import Reload from '../common/Reload'
import ComponentLoading from '../common/ComponentLoading'
import ProblemDetails from './components/ProblemDetails'
import ProblemWrite from './components/ProblemWrite'


export default function Forum() {
    const api = useAxios('protected')
    const location = useLocation()
    const { forum } = location.state || {}
    const [component, setComponent] = useState(null) // stores current component for POST or PUT action
    const [problems, setProblems] = useState([])
    const [loading, setLoading] = useState(false)
    const [subject, setSubject] = useState({})


    useEffect(() => {
        const getSubject = async () => {
            try {
                setLoading(true)
    
                const response = await api.get(`/subjects/${forum.subject.id}`)
    
                setSubject(response.data)
            } catch (error) {
                toast.error('Failed to get subject: ' + error.message)
            } finally {
                setLoading(false)
            }
        }

        getSubject()
    }, [])


    useEffect(() => {
        getProblems()
    }, [])

    const getProblems = async () => {
        try {
            setLoading(true)

            const response = await api.get('/problems', {
                params: {
                    forumId: forum.id
                }
            })

            setProblems(response.data.items)
        } catch (error) {
            toast.error('Failed to get problems: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const handlePost = () => {
        const current = {
            title: '',
            source: '',
            forumId: forum.id,
            tagsId: [],
            content: '',
            shareUrl: null
        }

        setComponent(<ProblemWrite problem={current} subject={subject} type='POST' cancel={() => setComponent(null)} />)
    }
    
    const handlePut = (problem) => {
        const current = {
            id: problem.id,
            title: problem.title,
            source: problem.source,
            forumId: problem.forumId,
            tagsId: problem.tags.map(tag => tag.id),
            content: problem.content,
            shareUrl: problem.shareUrl
        }

        setComponent(<ProblemWrite problem={current} subject={subject} type='PUT' cancel={() => setComponent(null)} />)
    }

    const handleDelete = async (problemId) => {
        const res = window.confirm('Want to delete this problem')

        if (!res) {
            return
        }

        try {
            setLoading(true)

            await api.delete(`/problems/${problemId}`)

            // remove deleted problem from problems list
            setProblems(prev => prev.filter(problem => problem.id !== problemId))
            
            toast.success('Problem has been deleted')
        } catch (error) {
            toast.error('Failed to DELETE(problem): ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const changeVisibility = async (problemId) => {
        try {
            await api.put(`/problems/${problemId}/visibility`)
            
            setProblems(prev => prev.map(p => {
                if (p.id === problemId) {
                    return {
                        ...p,
                        visible: !p.visible
                    }
                } else {
                    return p
                }
            }))

            toast.success('Problems\' visibility has been changed successfully')
        } catch (error) {
            toast.error('Failed to make post visible: ' + error.message)
        }
    }


    if (loading) {
        return <ComponentLoading />
    }

    return(
        <div style={styles.container}>
            { component && <ActionModal children={component} /> }
            
            <div style={styles.actionsWrapper}>
                <p style={styles.name}>{forum.name}</p>

                <Apply loading={loading} name="Post qo'shish" apply={handlePost} />

                <Reload loading={loading} action={getProblems}/>
            </div>
            
            <div style={styles.content}>
                { problems.map(problem => (
                    <ProblemDetails 
                        key={problem.id} 
                        problem={problem} 
                        handlePut={handlePut} 
                        handleDelete={handleDelete}
                        changeVisibility={changeVisibility}
                        actionable
                    />
                )) }
            </div>
        </div>
    )
}


const styles = {
    container: {
        // correct right overflow
        marginRight: 40
    },
    actionsWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        height: 45, 
        width: '100%',
        alignItems: 'center',
        padding: '0 20px', 
        backgroundColor: 'rgb(234, 237, 237)',
        position: 'sticky',
        top: 0,
        zIndex: 200,
    },
    name: {
        flex: 1,
        fontSize: 25,
        color: 'rgb(20, 20, 20)', 
        fontWeight: '700', 
        userSelect: 'none',
    },
    content: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        backgroundColor: 'rgb(255, 255, 255)', 
        padding: 10
    }
}