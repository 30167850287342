import React from 'react'
import { toast } from 'react-toastify'


export default function File({ url }) {

    const saveSimpleToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(url)

            toast.info('Image url has been copied')
        } catch (err) {
            toast.error('Could not copy image url: ' + err)
        }
    }

    const saveHtmlToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`<img src="${url}" alt="rasm" style="width: 80%; max-width: 600px; height: auto; display: block; margin: 0 auto"/>`)

            toast.info('Image url has been copied')
        } catch (err) {
            toast.error('Could not copy image url: ' + err)
        }
    }


    return(
        <div style={{border: '1px solid rgb(0, 0, 0)', margin: 10, display: 'flex', flexDirection: 'column'}}>
            <img src={url} alt="Tanlangan rasm" style={{ width: 400, height: 'auto'}}/>

            <div style={{display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'space-around', backgroundColor: 'rgb(234, 250, 241)'}}>
                <button
                    style={{width: 120, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(144, 148, 151)', border: 'none',  marginRight: 20}}
                    onClick={saveSimpleToClipboard}
                > oddiy link </button>

                <button
                    style={{width: 120, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(93, 173, 226)', border: 'none',  marginRight: 20}}
                    onClick={saveHtmlToClipboard}
                > html link </button>
            </div>

        </div>
    )
}
