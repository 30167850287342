import React, { useState } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../../auth/useAxios'
import Apply from '../../common/Apply'
import Close from '../../common/Close'
import Tags from '../../common/Tags'
import Difficulties from './Difficulties'
import TextInput from '../../common/TextInput'
import RenderContent from '../../common/RenderContent'


export default function SpecialTestWrite({ test, subject, type, cancel }) {
    const api = useAxios('protected')
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(test)

    const validate = (test) => {
        if (test.tagsId.length === 0) {
            throw new Error('Kamida bitta teg tanlang')
        } else if (!test.question || test.question.length < 10) {
            throw new Error("Savol kamida 10 ta belgidan iborat bo'lishi kerak")
        } else if (!test.solution || test.solution.length < 10) {
            throw new Error("Yechim kamida 10 ta belgidan iborat bo'lishi kerak")
        } else if (!test.trueAnswer || test.trueAnswer.length < 1) {
            throw new Error("Javob kamida 1 ta belgidan iborat bo'lishi kerak")
        } else if (!test.falseAnswer1 || test.falseAnswer1.length < 1) {
            throw new Error("Javob kamida 1 ta belgidan iborat bo'lishi kerak")
        } else if (!test.falseAnswer2 || test.falseAnswer2.length < 1) {
            throw new Error("Javob kamida 1 ta belgidan iborat bo'lishi kerak")
        } else if (!test.falseAnswer3 || test.falseAnswer3.length < 1) {
            throw new Error("Javob kamida 1 ta belgidan iborat bo'lishi kerak")
        }
    }

    const postTest = async () => {
        try {
            setLoading(true)

            validate(current)

            await api.post('/special-tests', current)
    
            cancel()
            toast.success('Maxsus test saqlandi.')
        } catch (error) {
            if (error.data) {
                toast.error('Maxsus testni saqlashda muammo vujudga keldi: ' + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    const putTest = async () => {
        try {
            setLoading(true)
            
            validate(current)

            await api.put(`/special-tests/${test.id}`, current)
    
            cancel()
            toast.success("Maxsus test o'zgartirildi.")
        } catch (error) {
            if (error.data) {
                toast.error('Maxsus testni saqlashda muammo vujudga keldi: ' + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }


    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <p style={styles.modalName}>Maxsus test ma'lumotlari</p>

                { type === 'POST' && <Apply loading={loading} name="QO'SHISH" apply={postTest}/> }

                { type === 'PUT' && <Apply loading={loading} name="O'ZGARTIRISH" apply={putTest} color1='rgb(187, 143, 206)' color2='rgb(210, 180, 222)'/> }

                <Close loading={loading} close={cancel}/>
            </div>


            <p style={styles.title}>Teglar:</p>
            <Tags tags={subject.tags} tagsId={current.tagsId} setter={(ids => setCurrent(prev => ({...prev, tagsId: ids })))} />
            

            <p style={styles.title}>Daraja:</p>
            <Difficulties difficulties={[current.difficulty]} handleChange={(d) => setCurrent(prev => ({...prev, difficulty: d}))}/>


            <p style={styles.title}>Savol:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.question || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        question: value
                    }))}
                    ratio={4}
                />


                <div style={{...styles.content, ...styles.question}}>
                    <RenderContent content={current.question} width={350}/>
                </div>
            </div>


            <p style={styles.title}>Yechim:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.solution || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        solution: value
                    }))}
                    ratio={4}
                />


                <div style={{...styles.content, ...styles.solution}}>
                    <RenderContent content={current.solution} width={350}/>
                </div>
            </div>


            <p style={styles.title}>To'g'ri javob:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.trueAnswer || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        trueAnswer: value
                    }))}
                />


                <div style={{...styles.content, ...styles.correct}}>
                    <RenderContent content={current.trueAnswer} width={350}/>
                </div>
            </div>


            <p style={styles.title}>Xato javob 1:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.falseAnswer1 || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        falseAnswer1: value
                    }))}
                />


                <div style={{...styles.content, ...styles.incorrect}}>
                    <RenderContent content={current.falseAnswer1} width={350}/>
                </div>
            </div>


            <p style={styles.title}>Xato javob 2:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.falseAnswer2 || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        falseAnswer2: value
                    }))}
                />


                <div style={{...styles.content, ...styles.incorrect}}>
                    <RenderContent content={current.falseAnswer2} width={350}/>
                </div>
            </div>


            <p style={styles.title}>Xato javob 3:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.falseAnswer3 || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        falseAnswer3: value
                    }))}
                />


                <div style={{...styles.content, ...styles.incorrect}}>
                    <RenderContent content={current.falseAnswer3} width={350}/>
                </div>
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10, 
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700'
    },
    title: {
        fontSize: 18, 
        fontWeight: '700',
        color: 'rgb(40, 40, 40)',
        marginTop: 10,
    },
    contentWrapper: {
        display: 'flex', 
        alignItems: 'flex-start',
    },
    content: {
        backgroundColor: 'rgb(255, 255, 255)', 
        marginLeft: 10,
    },
    question: {
        border: '2px solid rgb(52, 152, 219)',
    },
    correct: {
        border: '2px solid rgb(26, 188, 156)',
    },
    incorrect: {
        border: '2px solid rgb(231, 76, 60)',   
    },
    solution: {
        border: '2px solid rgb(243, 156, 18)',
    }
}
