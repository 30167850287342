import React from 'react'

import Author from '../../common/Author'
import Close from '../../common/Close'
import Difficulty from './Difficulty'
import Usage from './Usage'
import RenderContent from '../../common/RenderContent'


export default function SpecialTestSee({ test, cancel }) {
    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <p style={styles.modalName}>Maxsus test ma'lumotlari</p>
                
                <Difficulty name={test.difficulty}/>
                
                <Usage value={test.usageCount}/>
                
                <Close loading={false} close={cancel}/>
            </div>


            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={styles.questionWrapper}>
                    <Author author={test.createdBy} date={test.createdDate} action='posted'/>
                    <Author author={test.updatedBy} date={test.updatedDate} action='edited'/>

                    <div style={styles.tagsWrapper}>
                        { test.tags.map(tag => (<p key={tag.id} style={styles.tag}>{tag.name} </p>))}
                    </div>

                    <p style={styles.title}>Savol:</p>
                    <div style={{...styles.content, ...styles.question}}>
                        <RenderContent content={test.question} width={350}/>
                    </div>
                    
                    <p style={styles.title}>To'g'ri javob:</p>
                    <div style={{...styles.content, ...styles.correct}}>
                        <RenderContent content={test.trueAnswer} width={350}/>
                    </div>

                    <p style={styles.title}>Xato javob 1:</p>
                    <div style={{...styles.content, ...styles.incorrect}}>
                        <RenderContent content={test.falseAnswer1} width={350}/>
                    </div>
                    
                    <p style={styles.title}>Xato javob 2:</p>
                    <div style={{...styles.content, ...styles.incorrect}}>
                        <RenderContent content={test.falseAnswer2} width={350}/>
                    </div>
                    
                    <p style={styles.title}>Xato javob 3:</p>
                    <div style={{...styles.content, ...styles.incorrect}}>
                        <RenderContent content={test.falseAnswer3} width={350}/>
                    </div>
                </div>


                <div style={styles.answerWrapper}>
                    <p style={styles.title}>Yechim:</p>
                    <div style={{...styles.content, ...styles.solution}}>
                        <RenderContent content={test.solution} width={350}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 880,
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10,
        margin: '0 auto', 
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700',
        marginLeft: 15,
    },
    questionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 10px 10px 0',
        width: 410,
        borderRight: '1px solid rgb(19, 141, 117)', 
    },
    tagsWrapper: {
        display: 'flex',
        flex: 1, 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
    },
    tag: {
        fontSize: 15, 
        fontWeight: '600', 
        color: 'rgb(255, 255, 255)',
        userSelect: 'none',
        height: 20,
        borderRadius: 12, 
        padding: '6px 12px', 
        marginTop: 8,
        marginRight: 10, 
        border: '1.2px solid rgb(127, 179, 213)',
        backgroundColor: 'rgb(52, 152, 219)'
    },
    answerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0 10px 10px',
        width: 410,
        borderLeft: '1px solid rgb(19, 141, 117)', 
    },
    title: {
        fontSize: 18, 
        fontWeight: '700',
        color: 'rgb(40, 40, 40)',
        marginTop: 10,
    },
    content: {
        width: 400,
        marginTop: 3,
    },
    question: {
        border: '2px solid rgb(52, 152, 219)',
    },
    correct: {
        border: '2px solid rgb(26, 188, 156)',
    },
    incorrect: {
        border: '2px solid rgb(231, 76, 60)',   
    },
    solution: {
        border: '2px solid rgb(243, 156, 18)',
    }
}